<template>
  <div>
    <service-comp :type="type"></service-comp>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import ServiceComp from "@/components/admin/service/ServiceComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "edit-service",
  components: {ServiceComp},

  setup() {

    const type = ref('edit');
    onMounted(() => {
      setCurrentPageBreadcrumbs("Update Service", ["Services"] , checkPermission('services.store') ? 'create-service' : null);
    });


    return {
      getIllustrationsPath,
      type,
    };
  },

});
</script>

<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div class="col-md-3 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
            <el-form-item :prop="'name.'+language" :rules="rules['field']">
              <el-input v-model="objData.name[language]" :placeholder="`Name(${language})`"/>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Description ({{ language }})</label>
            <el-form-item :prop="'description.'+language" :rules="rules['field']">
              <el-input type="textarea" v-model="objData.description[language]"
                        :placeholder="`Description(${language})`"/>
            </el-form-item>
          </div>
        </div>

        <div class="row g-9 mb-7">
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Category</label>
            <el-form-item prop="category_id" :rules="rules['field']">
              <el-select v-model="objData.category_id" class="select-display-block">
                <el-option v-for="category in categories" :label="category.name" :value="category.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Location Type</label>
            <el-form-item prop="location_type_id" :rules="rules['field']">
              <el-select v-model="objData.location_type_id" class="select-display-block">
                <el-option v-for="locationType in locationTypes" :label="locationType.name"
                           :value="locationType.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Cities</label>
            <el-form-item prop="city_ids" :rules="rules['field']">
              <el-cascader class="full-width" v-model="objData.city_ids" placeholder="Select Cities" :options="countries" :props="{multiple: true}" collapse-tags clearable  filterable/>
            </el-form-item>
          </div>
<!--          <div class="col-md-3 fv-row">-->
<!--            <label class="required fs-6 fw-bold mb-2">City</label>-->
<!--            <el-form-item prop="city_id" :rules="rules['field']">-->
<!--              <el-select v-model="objData.city_id" clearable class="select-display-block"-->
<!--                         :disabled="loadingCities">-->
<!--                <el-option v-for="city in cities" :key="city.id" :label="city.name"-->
<!--                           :value="city.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Price Types</label>
            <el-form-item prop="price_type_ids" :rules="rules['field']">
              <el-select v-model="objData.price_type_ids" clearable multiple class="select-display-block">
                <el-option v-for="priceType in priceTypes" :key="priceType.id" :label="priceType.name"
                           :value="priceType.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-2 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active"/>
            </el-form-item>
          </div>
          <image-comp :element="objData.image" v-model="objData.image" col="col-md-3 fv-row" title="Image"></image-comp>
          <image-comp :element="objData.icon" v-model="objData.icon" col="col-md-3 fv-row" title="Icon"></image-comp>

        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Options</label>
            <el-form-item>
              <el-select v-model="option" clearable class="select-display-block">
                <el-option v-for="option in options" :key="option.id" :label="option.name"
                           :value="option.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row mt-17">
            <el-button type="primary" @click="addOption">Add</el-button>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row" v-for="(option,optionIndex) in objData.service_options">
            <h5>{{ option.option.name_tr }}
              <button
                  @click="addOptionValue(option.option.id)"
                  type="button"
                  class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end">
                <i class="fas fa-plus"></i>
              </button>
              <button
                  @click="deleteOption(optionIndex)"
                  type="button"
                  class="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end">
                <i class="fas fa-trash"></i>
              </button>

            </h5>
            <el-checkbox v-model="option.include_smaller" label="Include smaller" size="large"/>
            <el-checkbox v-model="option.is_required" label="Is required" size="large"/>
            <draggable
                tag="ul"
                :list="option.option_values"
                class="list-group"
                handle=".handle"
                item-key="item_key"
            >
              <template #item="{ element, index }">
                <li class="list-group-item">
                  <i class="fa fa-align-justify handle"></i>
                  <div class="row">
                    <div class="col-md-7">
                      <el-form-item :prop="'service_options.'+optionIndex+'.option_values.'+index+'.value'" :rules="rules['field']" label="Value">
                        <el-input v-model="element.value"/>
                      </el-form-item>
                    </div>
                    <div class="col-md-3">
                      <el-checkbox @click="checkIsDefault(option.option.id ,index)" v-model="element.is_default"
                                   label="Default" size="large"/>
                    </div>
                    <div class="col-md-1">
                      <button
                          @click="deleteOptionVaue(option.option.id ,index)"
                          type="button"
                          class="btn btn-sm btn-icon btn-color-danger btn-active-light-danger"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-flip="top-end">
                        <i class="fas fa-trash"></i>
                      </button>

                    </div>
                  </div>

                </li>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs, watch} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import {useRouter} from 'vue-router'
import draggable from "vuedraggable";
import {Delete} from '@element-plus/icons-vue'

export default defineComponent({
  name: "service-comp",
  components: {ImageComp, draggable},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref(['name', 'description', 'price_type_ids', 'service_options','service_option_deleted_ids','service_option_value_deleted_ids']);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);
    const categories = ref([]);
    const locationTypes = ref([]);
    const countries = ref([]);
    const cities = ref([]);
    const priceTypes = ref([]);
    const options = ref([]);
    const loadingCities = ref(false);

    objData.value = {
      name: {},
      description: {},
      image: '',
      icon : '',
      city_ids: [],
      location_type_id: '',
      price_type_ids: [],
      is_active: true,
      service_options: [],
      service_option_deleted_ids : [],
      service_option_value_deleted_ids : [],
    };

    const option = ref('');
    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.SERVICE.create);
            languages.value = response.data.data.languages;
            categories.value = response.data.data.categories;
            locationTypes.value = response.data.data.location_types;
            countries.value = response.data.data.countries;
            priceTypes.value = response.data.data.price_types;
            options.value = response.data.data.options;

            objData.value.service_option_deleted_ids = [];
            objData.value.service_option_value_deleted_ids = [];
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.SERVICE.edit(route.params.id));
            objData.value = response.data.data.service;
            languages.value = response.data.data.languages;
            categories.value = response.data.data.categories;
            locationTypes.value = response.data.data.location_types;
            countries.value = response.data.data.countries;
            priceTypes.value = response.data.data.price_types;
            options.value = response.data.data.options;

            objData.value.service_option_deleted_ids = [];
            objData.value.service_option_value_deleted_ids = [];

          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.SERVICE.show(route.params.id));
            objData.value = response.data.data.service;
            languages.value = response.data.data.languages;
            categories.value = response.data.data.categories;
            locationTypes.value = response.data.data.location_types;
            countries.value = response.data.data.countries;
            priceTypes.value = response.data.data.price_types;
            options.value = response.data.data.options;
          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      formData.append('city_ids' , JSON.stringify(getValueCityIds(objData.value.city_ids)));
      loading.value = true;
      ApiAxiosService.post(APIs.SERVICE.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'services-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      formData.append('city_ids' , JSON.stringify(getValueCityIds(objData.value.city_ids)));
      loading.value = true;
      formData.append('_method', 'put');
      ApiAxiosService.post(APIs.SERVICE.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'services-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    const addOption = () => {
      if (objData.value.service_options.find(el => el.option.id == option.value)) return;
      let getOption = options.value.find(el => el.id == option.value);
      objData.value.service_options.push({
        include_smaller: false, is_required: false,
        option: {id: getOption.id, name_tr: getOption.name,},
        option_values: [
          {value: '', is_default: false},
        ]
      });
    }
    const deleteOption = (index) => {
      if(objData.value.service_options[index].id) {
        objData.value.service_option_deleted_ids.push(objData.value.service_options[index].id);
      }
      objData.value.service_options.splice(index , 1);
    }
    const addOptionValue = (optionId) => {
      let getOption = objData.value.service_options.find(el => el.option.id == optionId);
      getOption.option_values.push({value: '', is_default: false});

    }
    const deleteOptionVaue = (id, index) => {
      let getServiceOption = objData.value.service_options.find(el => el.option.id == id);
      if(getServiceOption.option_values[index].id) {
        objData.value.service_option_value_deleted_ids.push(getServiceOption.option_values[index].id);
      }
      getServiceOption.option_values.splice(index , 1);
    }
    const checkIsDefault = (id, index) => {
      objData.value.service_options.find(el => el.option.id == id).option_values.forEach(function (item, itemIndex) {
        if (itemIndex !== index) item.is_default = false;
      });
    }

    const getValueCityIds = (value) => {
      let valueIds = [];
      value.forEach(function (item){
        console.log(item);
        valueIds.push(Array.isArray(item)  ? item[item.length-1] : item);
      });
      return valueIds;
    }
    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      isIndeterminate,
      languages,
      categories,
      locationTypes,
      countries,
      cities,
      priceTypes,
      options,
      option,
      loadingCities,
      Delete,

      addOption,
      deleteOption,
      addOptionValue,
      deleteOptionVaue,
      checkIsDefault,


    };
  },

});
</script>

